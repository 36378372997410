import React , { useEffect , useState  }  from 'react';
import './header.css';
import {Link} from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import magnifyingGlass from '../../assets/search.svg';
import contactus from '../../assets/mail.svg';
import closeicon from '../../assets/closeicon.svg';
import highrollerFullLogo from '../../assets/PandaLogo.png';
import MenuIcon from '../../assets/menu.svg';

import DataService from "../../data.service";

import gamepad from '../../assets/game.svg';

function HeaderComponent(props){
  const [showResults, setShowResults] = React.useState(false)
  const [searchText, setSearchText] = React.useState(0)
  const [hideSearchBtn, setHideSearchBtn] = React.useState(false)
  const [lolBg, setlolBg] = useState(0);

    const [menuOpenState, setmenuOpenState] = React.useState(false)

    const menuOpen = () => {
      setmenuOpenState(!menuOpenState)
    }
  const onClick = () => setShowResults(true)

  const navigate = useNavigate();

  const handleInputChange = event => {
    setSearchText(event.target.value);
   
  };

  const handleKeyDown = (e) => {
    if(e.target.value.length < 3){
      setHideSearchBtn(true)

    }else{
      setHideSearchBtn(false)

    }
    if (e.key === 'Enter') {
      navigate(`/search/${e.target.value}`, { replace: true })
      setSearchText(e.target.value);
    }
  }

  

  useEffect(() => {
    DataService.getHeaderBg().then(response => {
      setlolBg(response.data.logo); 
      console.log(response.data.logo)

  })
  .catch(e => {
      console.log(e);
  });
}, []);


  return (
    <>
        <div className="header d-none  d-sm-none d-lg-block">
          <div className="container">
            <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-1 col-3">
                    <Link to="/"><img alt="High_Roller_logo" className="desktopLogo"  src={`https://admin.pandamastersweeps.com/media/${lolBg}`}/></Link>
                </div>
                <div className="col-lg-11">
                  <div className="menuGrid">
                      <div><Link to="./">Home</Link></div>
                      <div><Link to="./about">About</Link></div>
                      <div><Link to="./games">Games</Link></div>
                      <div>
                        <div className='sarchInputWarpper'> 
                          <input type="text" className='searchInput'  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
                          <button><img className='magnifyGlass' src={magnifyingGlass}/></button>  
                        </div>

                      </div>
                      <div><Link to="./contact">Contact</Link></div>
                      <div><a target="_blank" href="https://pandamaster.vip:8888/index.html"><button className='downloadBtn'>Download</button></a></div>
                  </div>
                </div>
            </div>
          </div>
         
            {/* <div className="searchWarpper hideMobile">
                          
              <div className="inputWarpper">
             
                <input type="text"  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
              
              </div>
             
              <Link  to={`${hideSearchBtn === false && searchText !== 0 ? `/search/${searchText}` : '#'}`}>
                <button  className='searchButton'  >
               <img alt="IconImage" src={magnifyingGlass}/></button></Link>

            </div> */}

          
        </div>


        <div className="header d-block d-sm-block d-lg-none">
          <div className="container">
            <div className="row" style={{    alignItems: "center"}}>
                <div className="col-lg-3 col-9">
                    <Link to="/" className="mb-0"><img alt="High_Roller_logo" className="mobileLogo"  src={`https://admin.pandamastersweeps.com/media/${lolBg}`}/><span className="mobileLogo"></span></Link>
                </div>

               <div className="col-lg-3 col-3">
                   <img className="float-right" onClick={menuOpen} src={MenuIcon} />

               </div>
            </div>
          </div>
        </div>
        <div id="MenuBox" className={`${menuOpenState === true ? 'd-block' : 'd-none'}`}>
          <ul className="mobileList">
            <li><Link to="./">Home</Link></li>
            <li><Link to="./">About</Link></li>
            <li><Link to="./">Games</Link></li>
            <li><Link to="./">Contact</Link></li>
            <li><Link to="./" className="downloadBtn">Download</Link></li>
            
          </ul>
            <div className='sarchInputWarpper'> 
                          <input type="text" className='searchInput'  onKeyDown={handleKeyDown}  onChange={handleInputChange}  placeholder="Search Here" />
                          <button><img className='magnifyGlass' src={magnifyingGlass}/></button>  
                        </div>
        </div>
      
    </>
  );

};



export default HeaderComponent;