import React , { useEffect , useState  }  from 'react';
import highrollerFullLogo from '../../assets/PandaLogo.png';

import DataService from "../../data.service";
import {Link} from "react-router-dom";
import facebook from '../../assets/facebook.png';
import youtube from '../../assets/youtube.png';

import instagram from '../../assets/instagram.png';
import twitter from '../../assets/twitter.png';

import './footer.css';


function FooterComponent(){
    const [gameData, setGameData] = useState([]);
    const [settingData, setSettingData] = useState([]);

    const [subscribeData, setSubscribeData] = useState(0);
    const [validationEmail, setValidationEmail] = React.useState(0);

    useEffect(() => {
        // DataService.getAll('highly-rated',5)
        // .then(response => {
        //     setGameData(response.data.results);
  
        // })
        // .catch(e => {
        //     console.log(e);
        // });


        DataService.getContent('website-settings')
        .then(response => {
            setSettingData(response.data);
            console.log(response.data)
        })
        .catch(e => {
            console.log(e);
        });
    }, []);

    const handleInputChange = event => {
        setSubscribeData(event.target.value);
        const pattern = /[a-zA-Z0-9]+[\.]?([a-zA-Z0-9]+)?[\@][a-z]{3,9}[\.][a-z]{2,5}/g;
        const result = pattern.test(event.target.value);
        if(result===true){
            setValidationEmail(true);
        } else{
            setValidationEmail(false);
        }
      };

    const sendForm = () => {
        var data = {
            email: subscribeData,
        }
        DataService.subscribeData(data)
        .then(response => {
            alert("Subscription Succesfully. Do check your email frequently")
        })
        .catch(e => {
            console.log(e);
        });
    };
    
  return (
    <>
       
       <div className="footer">
           <div className="container">
               <div className="row">
                    <div className="col-lg-4 col-12">
                        <img alt="HighRoller_Logo" src={highrollerFullLogo} className="footerLogo" />
                        <p>{settingData.home_page_description}</p>
                    </div>
                    <div className="col-lg-3 col-12">
                        <h3>Our Games</h3>
                        <ul className='footerList'>
                            <li><Link  to="/game/recommended">Recommended Games</Link></li>
                            <li><Link  to="/game/popular">Popular Games</Link></li>
                            <li><Link  to="/game/trending">Trending Games</Link></li>
                            <li><Link  to="/game/latest">Latest Games</Link></li>
                            <li><Link  to="/game/highly-rated">Highly Rated Games</Link></li>
                        </ul>
                    </div>
                    <div className="col-lg-3 col-12">
                        <h3>Our Games</h3>
                        <ul className='footerList'>
                            <li><Link  to="/about">About Us</Link></li>
                            <li><Link  to="/faqs">FAQ's</Link></li>
                            <li><Link  to="/terms">Terms and Conditions</Link></li>
                            <li><Link  to="/privacy">Privacy Policy</Link></li>
                        </ul>
                    </div>

                    <div className="col-lg-2 col-12">
                        <h3>Get in Touch</h3>
                        <div className='socialMedia'>
                        <a  target="_blank" rel="noreferrer"  href={settingData.facebook_link}><img className='mediaLink' src={facebook} alt='facebookLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.twitter_link}><img className='mediaLink' src={twitter} alt='twitterLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.youtube_channel}><img className='mediaLink' src={youtube} alt='youtubeLink'/></a>
                        <a target="_blank" rel="noreferrer" href={settingData.youtube_channel}><img className='mediaLink' src={instagram} alt='youtubeLink'/></a>

                        </div>
                      
                    </div>
               </div>
           </div>
        </div>

        <div className="copyrightFooter">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <p className='text-center'>{settingData.copyright_text}</p>

                    </div>

                </div>
            </div>
        </div>
        
       
    </>
  );

};



export default FooterComponent;