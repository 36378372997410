import React , { useEffect, useRef , useState  }  from 'react';
import {Link} from "react-router-dom";
import { Swiper, SwiperSlide } from 'swiper/react';
import DataService from "../../../data.service";

import "swiper/css";
import "swiper/css/navigation"
import './banner.css';
import removeBg2 from "../../../assets/removeBg2.png"
import SwiperCore, {
  Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([Navigation]);

const BannerComponent = () => {
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    DataService.getAll('latest',4)
    .then(response => {
        setGameData(response.data.results);
    }).catch(e => {
        console.log(e);
    });

   
}, []);

  return (
    <>
       <div className="bannerSection">
   
    <Swiper  navigation={true} spaceBetween={50} slidesPerView={1}>
    {gameData.slice(0, 3).map((item, i) => (

      <SwiperSlide>
     
        <div>
            <div className="row" style={{alignItems : "center"}}>
                <div className="col-lg-9  col-sm-9 col-12">
                   <img src={item.image} className="w-100" alt="background_Image"/>
                </div>
                <div className="col-lg-3 col-sm-3 col-12">
                   
                  <p className="bannerType"> {item.game_type === 1 ? ('Fish' ) : ('')}
                          {item.game_type === 2 ? ('Slot' ) : ('')}
                          {item.game_type === 3 ? ('Keno' ) : ('')}
                          {item.game_type === 4 ? ('Other' ) : ('')}</p>
                  <h2 className="bannerHeading">{item.name}</h2>
                  <a target="_blank"  href="https://pandamaster.vip:8888/index.html"><button className="btn bannerBtn">Play Now</button></a>
                </div>

            </div>
        </div>


      </SwiperSlide>
  ))}

    </Swiper>
       </div>

    </>
  );
};



export default BannerComponent;