import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";


import './privacy.css';


function PrivacyComponent(){
  const [settingData, setSettingData] = useState([]);

  useEffect(() => {

    DataService.getContent('website-settings')
        .then(response => {
            setSettingData(response.data);
        })
        .catch(e => {
            console.log(e);
        });
}, []);

    
  return (
    <>  
      <div className="page ">
        <div className="container">
          <div className="pageBox pageBoxx">

              <div dangerouslySetInnerHTML={{__html: settingData.privacy_policy}}></div>
          </div>
        </div>
      </div>
    </>
  );

};



export default PrivacyComponent;