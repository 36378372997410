import './App.css';
import React , { useState  }  from 'react';
import HeaderComponent from './components/header/header';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomepageComponent from './components/homepage/homepage';
import GamePageComponent from './components/games/games';
import FooterComponent from './components/footer/footer';
import ContactComponent from './components/contact/contact';
import AboutComponent from './components/about/about';
import PrivacyComponent from './components/privacy/privacy';
import FaqsComponent from './components/faqs/faqs';
import TermsComponent from './components/terms/terms';
import StoreComponent from './components/store/store';
import TrendingComponent from './components/trending/trending';
import GamedetailComponent from './components/gamedetail/gamedetail';
import NotFound from './components/notfound/notfound';
import SearchComponent from './components/search/search';
import ScrollToTop from './scrolltotop';


function App() {
 

  return (
    <>    
    <Router >
        <ScrollToTop />
        <HeaderComponent />
        <Routes>
              <Route path="/" element={<HomepageComponent />} />
              <Route path="/games" element={<GamePageComponent />} />
              <Route path="/game/:gametype" element={<GamePageComponent />} />
              <Route path="/contact" element={<ContactComponent />} />
              <Route path="/about" element={<AboutComponent />} />
              <Route path="/faqs" element={<FaqsComponent />} />
              <Route path="/terms" element={<TermsComponent />} />
              <Route path="/store" element={<StoreComponent />} />
              <Route path="/trending" element={<TrendingComponent />} />
              <Route path="/gamedetail/:id" element={<GamedetailComponent />} />
              <Route path="/search/" element={<SearchComponent />} />
              <Route path="/search/:string" element={<SearchComponent />} />
              <Route path="/privacy" element={<PrivacyComponent />} />
              <Route path="*"  element={<NotFound />} />
        </Routes>
        <FooterComponent />  
    </Router>
    </>

  );
}

export default App;
