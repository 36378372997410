import React , { useEffect , useState  }  from 'react';
import FormComponent from '../homepage/form/forms';
import DataService from "../../data.service";


import './store.css';


function StoreComponent(){

  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
  

    
  DataService.getHeaderBg().then(response => {
    setlolBg(response.data.distributor_img); 
})
.catch(e => {
    console.log(e);
});

}, []);

    
  return (
    <>
    <div className="page storebg">
      <div className="container">
        <div className="pageBox pageBoxx storeImgPage">
          <h3  className="pageBoxTitle text-center">APPLICATION FORM FOR DISTRIBUTORS STORES </h3>
          <p>Skill Gaming Solutionsis where it all happens! An online fish game store, our app provides you a platform where you can distribute and make money from the comfort of your home.</p>
          <div className="formBox">
            <div className="contactGrid">
              <img src={`https://admin.highrollersweeps.com/media/${lolBg}`} />
              <div className="formBox">
                <FormComponent />
              </div>
            </div>

          </div>
        </div>


        <div  className="pageBox  pageBoxx ">

            <h3 className="pageBoxTitle">DISTRIBUTION AND STORES</h3>
            <p>Are you into online skill reel and fish games? Would you like us to provide you the skill games online to support your business? Skill Gaming Solutionsdistributors can buy virtual credits with us, so “credit” is the product we are selling. If you have Larger sales volumes and broader channels, we can set you up with a higher distributor level, and the profit space we provide will turn larger. With the strong support of our app development team, we got your back all the time. We are looking for distributors who are business motivated in all 50 US states. Skill Gaming Solutions provides distribution to more prominent distributors, and those distributors provide to sub-distributor and stores. If we receive any complaint regarding the misuse of the software, then we will take action legally. We mean it when we say anytime, anywhere! Our app is designed to be used on any device from the comfort of your own home and your own time.</p>
          
            <p>Play the most exciting fish games, reels, keno, and much more with our fish game and reels app. We’ve got free play, bonuses, community prizes, multipliers, lasers, and many extra features. Play on any device, anytime, anywhere!</p>
          


        </div>

        <div  className="pageBox  pageBoxx ">

            <h3  className="pageBoxTitle">DISTRIBUTION AND STORES</h3>
            <p>Play the most exciting fish games, reels, keno, and much more with our fish game and reels app. We’ve got free play, bonuses, community prizes, multipliers, lasers, and many extra features. Play on any device, anytime, anywhere!</p>
          

            <div className="pageBoxBox">
              We do not provide forgotten PASSWORD<br></br>
              We do not accept player account<br></br> 
              We do not accept to recharge and cash out <br></br>
              We do not accept download APP for players <br></br>
              Please leave your business requirement </div>

            <p>If you are a distributor and want to log in to experience or customize your system, please contact us</p>

        </div>
      </div>
    
    </div>
    </>
  );

};



export default StoreComponent;