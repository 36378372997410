import React , { useEffect, useRef , useState  }  from 'react';
import {Link} from "react-router-dom";

import './featuregame.css';

import DataService from "../../../data.service";

const FeatureComponent = () => {
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    DataService.getAll('recommended').then(response => {
        setGameData(response.data.results); 
        console.log(response.data.results)
        console.log("Hello")
    })
    .catch(e => {
        console.log(e);
    });


    // DataService.getHeaderBg().then(response => {
    //   setlolBg(response.data.recommended_game_bg); 
    // })
    // .catch(e => {
    //     console.log(e);
    // });
}, []);

  // https://admin.pandamastersweeps.com/game-recommended/
  
  return (
    <>
       <div className="featureSection">
   
          <div className="container">
            <div className="row">
                <div className="col-12">
                  <h3 className='homePageLatestTitle'>Recommended Game</h3>

                </div>
            </div>
            <div className="row">

            {gameData.slice(0, 6).map((item, i) => (
              <Link  className="col-lg-4 col-sm-6 col-12" to={`/gamedetail/${item.id}`}  key={i}> 
             <div>
                <div className="homepageLatestCard homepageFeatureCard">
                    <img src={item.image} className='w-100' />
                    <div className="homepageLatestCardContent">
                      <h3 className='title'>{item.name}</h3>
                      {/* <p className='cardDescription'>Ocean Monster</p> */}

                      <p className='cardDescription'> {item.game_type === 1 ? ('Fish' ) : ('')}
                          {item.game_type === 2 ? ('Slot' ) : ('')}
                          {item.game_type === 3 ? ('Keno' ) : ('')}
                          {item.game_type === 4 ? ('Other' ) : ('')}</p>
                    </div>
                    
                </div>
              </div>
            
              </Link>
            ))}


              
            </div>
          </div>
          <div className="container">
            <div className="row">
              <Link className='homepageLatestShowMore'  to="/game/recommended"><button >Show More</button></Link>
            </div>
          </div>
    
       </div>

    </>
  );
};



export default FeatureComponent;