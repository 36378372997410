import React , { useEffect, useRef , useState  }  from 'react';
 import './mostpopular.css';
import DataService from "../../../data.service";
import {Link} from "react-router-dom";
import './mostpopular.css';
import pandaBigLogo from '../../../assets/pandaBigLogo.png';

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation"
import SwiperCore, { Autoplay,Navigation} from 'swiper';
SwiperCore.use([Autoplay,Navigation]);


const PopularComponent = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
    { width: 1920, itemsToShow: 6 }
  ];
  const [gameData, setGameData] = useState([]);
  const [noofItem, setNoofItem] = useState(0);
  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
      DataService.getAll('popular',12)
      .then(response => {
          setGameData(response.data.results);      
          setNoofItem(response.data.count)     

      })
      .catch(e => {
          console.log(e);
      });

      
      DataService.getHeaderBg().then(response => {
        setlolBg(response.data.popular_bg); 
      })
      .catch(e => {
          console.log(e);
      });


  }, []);



  
  return (
    <>
       <div className="popularSection">
         <div className="container">
         {gameData.slice(0, 1).map((item, i) => (

           <div className="row" style={{alignItems:"center"}}>
              <div className="col-lg-6 col-sm-6 col-12 ">
                  <h3 className='popularTitle'>Newest Skilled Fish Games from <br /><span>{item.name}</span></h3>
                  <div>{item.description}</div>
              </div>
              <div className='col-lg-6 col-sm-6 col-12'>
                  <img src={item.image} className='d-block mx-auto w-100' />
              </div>
           </div>
          ))}
         </div>
       </div>

    </>
  );
};



export default PopularComponent;