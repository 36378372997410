import React , { useEffect, useRef , useState  }  from 'react';
import {Link} from "react-router-dom";
// import video from 'https://secureservercdn.net/198.71.233.28/u8t.133.myftpupload.com/wp-content/uploads/2022/01/5_6239804409765692599.mp4'

import './videogame.css';

import DataService from "../../../data.service";

const VideGameComponent = () => {
  const [gameData, setGameData] = useState([]);

  
  useEffect(() => {
    DataService.getAll('recommended').then(response => {
        setGameData(response.data.results); 
        console.log(response.data.results)
        console.log("Hello")
    })
    .catch(e => {
        console.log(e);
    });


    // DataService.getHeaderBg().then(response => {
    //   setlolBg(response.data.recommended_game_bg); 
    // })
    // .catch(e => {
    //     console.log(e);
    // });
}, []);

  return (
    <>
       <div className="featureSection">
   
          <div className="container">
          {gameData.slice(0, 1).map((item, i) => (

            <div className="row" style={{alignItems:"center"}}>
              
              <div className="col-lg-6">
              {/* <video width="750" height="500" controls >
                <source src={video} type="video/mp4"/>
              </video> */}
                  <div className="videowarpper">
                    <div className="video-container mb-0">
                          <iframe src={`https://www.youtube.com/embed/${item.youtube}`} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                    </div>
                  </div>
                  
              </div>
              <div className="col-lg-6">
                  <h3 className='videoGameTitle'>Inroducing Panda Master newest Game <br></br><span>{item.name}</span></h3>
                  
                    <Link className='homepageLatestShowMore VideoGameShowMore' to={`./gamedetail/${item.id}`}><button >Show More</button></Link>
                 
              </div>
            </div>

        ))}

          </div>  
          
       </div>

    </>
  );
};



export default VideGameComponent;