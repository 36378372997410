import React , { useEffect , useState  }  from 'react';


import './about.css';
import DataService from "../../data.service";
// anydevice

function AboutComponent(){
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    DataService.getContent('about-us')
    .then(response => {
        setGameData(response.data.results);
       

    })
    .catch(e => {
        console.log(e);
    });
}, []);
  return (
    <>
     <div className="page">
          <div className="container">
          {gameData.map((item, i) => (
              <div  key={i} >
                <div className='pageBox pageBoxx'>
                  <div className="termsBox">
                    <h3 className='termHeading'>About Us</h3>
                    <div  dangerouslySetInnerHTML={{__html: item.para1}}></div>
                    <div  dangerouslySetInnerHTML={{__html: item.para2}}></div> 
                    <div></div>
                  </div>
                
                  <div className="termsBox marginTop15">
                    <div  dangerouslySetInnerHTML={{__html: item.para3}}></div>
                    <div className='termsBoxTwo'  dangerouslySetInnerHTML={{__html: item.para4}}></div> 
                  </div>
                </div>
                </div>
               
     
            ))}
          </div>
    
    </div>
    </>
  );

};



export default AboutComponent;