import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";

import FormComponent from '../homepage/form/forms';

import './contact.css';

function ContactComponent(){
  const [lolBg, setlolBg] = useState(0);

  useEffect(() => {
  

    
  DataService.getHeaderBg().then(response => {
    setlolBg(response.data.contact_bg); 
  })
  .catch(e => {
      console.log(e);
  });

}, []);

    
  return (
    <>
    <div className="page contactPage">
      <div className="container">
        <div className="contactGrid">
            <img src={`https://admin.pandamastersweeps.com/media/${lolBg}`} className='contactGridImage' />
            <div className="formBox">
              <FormComponent />
            </div>
        </div>
      </div>
    
    </div>
      
    </>
  );

};



export default ContactComponent;