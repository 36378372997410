import React , { useEffect , useState  }  from 'react';
import DataService from "../../data.service";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './faqs.css';
import { Accordion,  AccordionItem,  AccordionItemHeading,  AccordionItemButton,  AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

function FaqsComponent(){
    const [gameData, setGameData] = useState([]);
    const [gameDatatwo, setGameDataTwo] = useState([]);
    const [gameDatathree, setGameDataThree] = useState([]);

    useEffect(() => {
        DataService.getData('faq',100)
        .then(response => {
            setGameData(response.data.results);
            setGameDataTwo(response.data.results);
            setGameDataThree(response.data.results);

        })
        .catch(e => {
            console.log(e);
        });
    }, []);


  return (
    <>
    <div className="page">
        <div className="container">
        <Tabs>
            <TabList>   
                <Tab>Game</Tab>
                <Tab>General</Tab>
                <Tab>Business</Tab>
            </TabList>
            <TabPanel>
                <Accordion  allowMultipleExpanded={true} allowZeroExpanded={true} >
                    {gameData.filter(name => name.faq_type === "GM").map((item, i) => (
                            <AccordionItem   >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.question}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div dangerouslySetInnerHTML={{__html: item.answer}}></div>
                                </AccordionItemPanel>
                            </AccordionItem>
                    ))}
                </Accordion>
            </TabPanel>
            <TabPanel>
            <Accordion >
                {gameData.filter(name => name.faq_type === "GR").map((item, i) => (
                            <AccordionItem   >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.question}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div dangerouslySetInnerHTML={{__html: item.answer}}></div>
                                </AccordionItemPanel>
                            </AccordionItem>
                ))}
                </Accordion>
            </TabPanel>
            <TabPanel>
                <Accordion>
            {gameDatathree.filter(name => name.faq_type === "BS").map((item, i) => (
                            <AccordionItem  >
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        {item.question}
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <div dangerouslySetInnerHTML={{__html: item.answer}}></div>
                                </AccordionItemPanel>
                            </AccordionItem>
                    ))}
                    </Accordion>
            </TabPanel>
        </Tabs>


        </div>
                  
      


    
    </div>
     
    </>
  );

};



export default FaqsComponent;